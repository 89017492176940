// 企业认证页
<template>
  <div class="sld_authen_info">
    <MemberTitle :memberTitle="L['企业认证']"></MemberTitle>
    <div class="container">
      <div class="unAuthen" v-if="step == 1">
        <!-- 未认证 -->
        <div class="bd6 flex-col">
          <div class="layer3 flex-col">
            <div class="layer6 flex-row">
              <span class="word26">企业会员权益</span>
            </div>
            <div class="bd7 flex-row">
              <div class="box33-0 flex-col">
                <div class="outer4-0 flex-col">
                  <img class="img1-0" referrerpolicy="no-referrer" :src="pic1" />
                  <span class="txt11-0">信用帐期</span>
                  <span class="txt11-0">信用账期服务，先采购后付款</span>
                </div>
              </div>
              <div class="box33-1 flex-col">
                <div class="outer4-1 flex-col">
                  <img class="img1-1" referrerpolicy="no-referrer" :src="pic2" />
                  <span class="txt11-1">折扣价</span>
                  <span class="txt11-1">产品折扣价采购</span>
                </div>
              </div>
              <div class="box33-2 flex-col">
                <div class="outer4-2 flex-col">
                  <img class="img1-2" referrerpolicy="no-referrer" :src="pic3" />
                  <span class="txt11-2">物料管理</span>
                  <span class="txt11-2">海量产品物料管理</span>
                </div>
              </div>
              <div class="box33-3 flex-col">
                <div class="outer4-3 flex-col">
                  <img class="img1-3" referrerpolicy="no-referrer" :src="pic4" />
                  <span class="txt11-3">组织管理</span>
                  <span class="txt11-3">管理本公司的设计师人员</span>
                </div>
              </div>
            </div>
          </div>
          <div class="layer4 flex-row">
            <button class="layer5 flex-col" @click="nextStep">
              <span class="word25">申请认证</span>
            </button>
          </div>
        </div>
      </div>
      <div class="writeing" v-else-if="step == 2 || step == 6">
        <!-- 填写信息 -->
        <div class="bd2 flex-col">
          <div class="mod5 flex-col">
            <el-form :model="form.data"  ref="ruleForm"> <!-- :rules="rules" -->
              <span class="info12">公司信息</span>
              <el-form-item prop="companyName">
                <div class="mod5_item">
                  <span><i class="active">公司名称：</i></span>
                  <el-input v-if="step == 2" placeholder="请输入公司名称" v-model="form.data.companyName" autocomplete="off"
                    maxlength="25" show-word-limit clearable />
                  <div v-else class="onlyread">{{ form.data.companyName }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="enterpriseName">
                <div class="mod5_item">
                  <span>部门名称：</span>
                  <el-input v-if="step == 2" placeholder="请输入部门名称" v-model="form.data.enterpriseName" autocomplete="off"
                    maxlength="25" show-word-limit clearable />
                  <div v-else class="onlyread">{{ form.data.enterpriseName }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="socialCreditCode">
                <div class="mod5_item">
                  <span>社会统一信用代码：</span>
                  <el-input v-if="step == 2" placeholder="请输入社会统一信用代码" v-model="form.data.socialCreditCode"
                    maxlength="18" show-word-limit clearable />
                  <div v-else class="onlyread">{{ form.data.socialCreditCode }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="addressAll">
                <div class="mod5_item">
                  <span>所在地：</span>
                  <!-- <el-input placeholder="请输入所在地" /> -->
                  <el-cascader v-if="step == 2" ref="cascaderAddr" v-model="form.data.addressAll"
                    :options="areaDataArray" placeholder="请选择所在地区" :props="cascaderProps" clearable
                    @change="changeAddress"></el-cascader>
                  <div v-else class="onlyread">{{ form.data.addressDesc }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="detailAddress">
                <div class="mod5_item flex-start">
                  <span>详细地址：</span>
                  <el-input v-if="step == 2" type="textarea" :rows="2" autocomplete="off" clearable maxlength="40"
                    placeholder="请输入公司详细地址" show-word-limit v-model="form.data.detailAddress" resize="none" />
                  <div v-else class="onlyread">
                    {{ form.data.detailAddress }}
                  </div>
                </div>
              </el-form-item>
              <el-form-item prop="contactName">
                <div class="mod5_item">
                  <span>联系人：</span>
                  <el-input v-if="step == 2" placeholder="请输入联系人名称" v-model="form.data.contactName" maxlength="20"
                    clearable />
                  <div v-else class="onlyread">{{ form.data.contactName }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="contactPhone">
                <div class="mod5_item">
                  <span>联系人手机号：</span>
                  <el-input v-if="step == 2" placeholder="请输入联系人手机号" v-model="form.data.contactPhone" maxlength="13"
                    clearable />
                  <div v-else class="onlyread">{{ form.data.contactPhone }}</div>
                </div>
              </el-form-item>
              <el-form-item prop="enterpriseEmail">
                <div class="mod5_item">
                  <span>企业邮箱：</span>
                  <el-input v-if="step == 2" placeholder="请输入企业邮箱" v-model="form.data.enterpriseEmail" maxlength="32"
                    clearable />
                  <div v-else class="onlyread">{{ form.data.enterpriseEmail || '--' }}</div>
                </div>
              </el-form-item>
              <span class="info12 info13">营业执照信息</span>
              <div class="mod6_item">
                <span>营业执照：</span>
                <div class="mod6_item_add mod6_item_pic">
                  <span v-if="!form.data.businessLicense">+</span>
                  <img v-else :src="businessLicenseUrl ? businessLicenseUrl : form.data.businessLicense" />
                  <input v-if="step == 2" accept=".jpg,.jpeg,.png" type="file" class="mod6_input" @change="getImage"
                    ref="imageFile" data-type="businessLicense" />
                </div>
              </div>
              <span class="info12 info13">法人身份信息</span>
              <div class="mod6_item">
                <span>身份证人像页：</span>
                <div class="mod6_item_add mod6_item_pic">
                  <span v-if="!form.data.idFrontImage">+</span>
                  <img v-else :src="idFrontImageUrl ? idFrontImageUrl : form.data.idFrontImage" />
                  <input v-if="step == 2" accept=".jpg,.jpeg,.png" type="file" class="mod6_input" @change="getImage"
                    ref="imageFile" data-type="idFrontImage" />
                </div>
              </div>
              <div class="mod6_item">
                <span>身份证国徽页：</span>
                <div class="mod6_item_add mod6_item_pic">
                  <span v-if="!form.data.idBackImage">+</span>
                  <img v-else :src="idBackImageUrl ? idBackImageUrl : form.data.idBackImage" />
                  <input v-if="step == 2" accept=".jpg,.jpeg,.png" type="file" class="mod6_input" @change="getImage"
                    ref="imageFile" data-type="idBackImage" />
                </div>
              </div>
              <div v-if="step == 2" class="submit" @click="nextStep">提交</div>
            </el-form>
            <!-- <view v-if="step == 6" class="box4 flex-col" @click="nextStep">编辑</view> -->
          </div>
        </div>
      </div>
      <div class="waiting" v-else>
        <template v-if="step == 3">
          <img class="img2" referrerpolicy="no-referrer" :src="pic_wait"><span class="word25">审核中…</span>
        </template>
        <template v-else-if="step == 4">
          <img class="img2" referrerpolicy="no-referrer" :src="pic_success"><span class="word25">认证成功！</span>
          <view class="box4 flex-col" @click="nextStep">确定</view>
        </template>
        <template v-else-if="step == 5">
          <img class="img2" referrerpolicy="no-referrer" :src="pic_fail"><span class="word25">认证失败！</span>
          <span class="word26">失败原因：{{ refuseReason }}</span>
          <view class="box4 flex-col" @click="nextStep">重新认证</view>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
import { useStore } from 'vuex';
import MemberTitle from "../../components/MemberTitle";
import { useRouter } from "vue-router";
import areaData from "../../assets/area.json";
import { ElMessage } from "element-plus";
export default {
  name: "MemberAuthen",
  components: {
    MemberTitle
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const L = proxy.$getCurLanguage();
    const router = useRouter();
    const store = useStore();
    const step = ref(0);
    const pic1 = require('@/assets/member/authen_zhangqi.png');
    const pic2 = require('@/assets/member/authen_zhekou.png');
    const pic3 = require('@/assets/member/authen_wuliao.png');
    const pic4 = require('@/assets/member/authen_zuzhi.png');
    const pic_wait = require('@/assets/member/authen_wait.png');
    const pic_success = require('@/assets/member/authen_success.png');
    const pic_fail = require('@/assets/member/authen_fail.png');
    const area = ref();
    const areaDataArray = areaData;
    const cascaderProps = { label: "regionName", value: "regionCode" };
    const form = reactive({ data: {} });
    const ruleForm = ref(null);
    const refuseReason = ref(''); //拒绝原因
    const rules = ref({
      //校验
      companyName: [
        { required: true, message: "请输入公司名称", trigger: "change" },
      ],
      enterpriseName: [
        { required: true, message: "请输入部门名称", trigger: "change" },
      ],
      socialCreditCode: [
        { required: true, message: "请输入社会统一信用代码", trigger: "change" },
        {
          pattern: /^[0-9A-Z]{2}\d{6}[0-9A-Z]{10}$/,
          message: "请输入正确的社会统一信用代码",
          trigger: "change",
        },
      ],
      addressAll: [
        { required: true, message: "请选择所在地区", trigger: "change" },
      ],
      detailAddress: [
        { required: true, message: "请输入公司详细地址", trigger: "change" },
        { min: 5, max: 60, message: "请输入5~40个字符", trigger: "change" },
      ],
      contactName: [
        { required: true, message: "请输入联系人名称", trigger: "change" },
      ],
      contactPhone: [
        { required: true, message: "请输入联系人手机号", trigger: "blur" },
        {
          pattern: /^((\+?86)|(\(\+86\)))?(1[3-9]\d{9}$)|(\d{4}-)\d{6,8}$/,
          message: "请输入正确的电话号码",
          trigger: "blur",
        },
      ],
      enterpriseEmail: [
        {
          pattern: /^([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/,
          message: "请输入正确的邮箱",
          trigger: "change",
        }
      ]
    });

    const cascaderAddr = ref(null);
    const editType = ref(false);  //是否是编辑状态

    const initData = () => {  //初始化数据
      form.data.companyName = "";
      form.data.enterpriseName = "";
      form.data.socialCreditCode = "";
      form.data.addressAll = "";
      form.data.addressDesc = "";
      form.data.detailAddress = "";
      form.data.contactName = "";
      form.data.contactPhone = "";
      form.data.enterpriseEmail = "";
      form.data.businessLicense = "";
      form.data.idFrontImage = "";
      form.data.idBackImage = "";
    }
    const businessLicenseUrl = ref('');
    const idFrontImageUrl = ref('');
    const idBackImageUrl = ref('');

    const getState = () => {  //获取认证状态 0,未申请，1-待审核；2-审核通过；3-审核失败
      proxy.$get("v3/member/front/enterprise/state")
        .then((res) => {
          if (res.state == 200) {
            if (res.data.state == 0) {
              step.value = 1;
            } else if (res.data.state == 1) {
              step.value = 3;
            } else if (res.data.state == 2) {
              step.value = 6;
              getInfo();
              editType.value = true;
              if (store.state.memberInfo.memberType == 1) { //会员状态更新
                proxy.$get('v3/member/front/member/centerInfo').then(response => {
                  if (response.state == 200) {
                    store.commit("updateMemberInfo", response.data); //将会员信息数据存储到vuex的store中
                  }
                })
              }
            } else if (res.data.state == 3) {
              step.value = 5;
              refuseReason.value = res.data.refuseReason;
              getInfo();
            }
          }
        })
    }

    const getInfo = () => { //获取认证信息
      proxy.$get("v3/member/front/enterprise/detail")
        .then((res) => {
          if (res.data.enterpriseId) {
            editType.value = true;
          }
          if (res.state == 200) {
            form.data.companyName = res.data.companyName;
            form.data.enterpriseName = res.data.enterpriseName;
            form.data.socialCreditCode = res.data.socialCreditCode;
            if (step.value == 6&&res.data.addressAll) {
              form.data.addressDesc = res.data.addressAll;
            }
            let addressAll = null;
            if (res.data.districtCode && res.data.districtCode != '0') {
              addressAll.push(
                res.data.provinceCode,
                res.data.cityCode,
                res.data.districtCode
              );
            } 
            // else {
            //   addressAll.push(
            //     res.data.provinceCode,
            //     res.data.cityCode
            //   );
            // }
            form.data.addressAll = addressAll;
            form.data.detailAddress = res.data.detailAddress;
            form.data.contactName = res.data.contactName;
            form.data.contactPhone = res.data.contactPhone;
            form.data.enterpriseEmail = res.data.enterpriseEmail;
            form.data.businessLicense = res.data.businessLicense;
            form.data.idFrontImage = res.data.idFrontImage;
            form.data.idBackImage = res.data.idBackImage;
            businessLicenseUrl.value = res.data.businessLicenseUrl;
            idFrontImageUrl.value = res.data.idFrontImageUrl;
            idBackImageUrl.value = res.data.idBackImageUrl;
          }
        })
    }

    const nextStep = () => {
      if (step.value == 1) {
        //申请认证
        step.value = 2;
      } else if (step.value == 2) {
        //填写信息 / 认证通过--编辑
        ruleForm.value.validate((valid) => {
          if (valid) {
            // if (!form.data.businessLicense) {
            //   ElMessage.error("请上传营业执照");
            //   return;
            // } else if (!form.data.idFrontImage) {
            //   ElMessage.error("请上传身份证人像页");
            //   return;
            // } else if (!form.data.idBackImage) {
            //   ElMessage.error("请上传身份证国徽页");
            //   return;
            // }
            let param = form.data;
            if(form.data.addressAll&&form.data.addressAll[0]&&form.data.addressAll[1]){
                param.provinceCode = form.data.addressAll[0];
                param.cityCode = form.data.addressAll[1];
            }
            if (form.data.addressAll&&form.data.addressAll.length > 2) {
              param.districtCode = form.data.addressAll[2];
            } else {
              param.districtCode = '';
            }
            if(cascaderAddr.value&&cascaderAddr.value
              .getCheckedNodes()[0]&&cascaderAddr.value
              .getCheckedNodes()[0]
              .pathLabels){
                  param.addressAll = cascaderAddr.value
              .getCheckedNodes()[0]
              .pathLabels.join("");
              }
            
            param.addressDesc = param.addressAll;
            let url = editType.value ?
              'v3/member/front/enterprise/update' :
              'v3/member/front/enterprise/apply'
            proxy.$post(url, param)
              .then((res) => {
                window.scrollTo(0, 0);
                if (res.state == 200) {
                  ElMessage.success(res.msg);
                  step.value = 3;
                } else {
                  ElMessage.error(res.msg);
                  let addressAll = [];
                  if (param.districtCode) {
                    addressAll.push(
                      param.provinceCode,
                      param.cityCode,
                      param.districtCode
                    );
                  } else {
                    addressAll.push(
                      param.provinceCode,
                      param.cityCode
                    );
                  }
                  form.data.addressAll = addressAll;
                }
              });
          } else {
            window.scrollTo(0, 0);
            btnLoading.value = false;
            return;
          }
        });
      } else if (step.value == 3) {
        //审核中
      } else if (step.value == 4) {
        //认证成功
        step.value = 6;
        // router.back()
      } else if (step.value == 5) {
        //认证失败
        step.value = 2;
      } else {
        step.value = 2
      }
    };

    //上传图片
    const getImage = (e) => {
      let type = e.currentTarget.dataset.type;
      if (e.target.files[0].size > 20 * 1024 * 1024) {
        ElMessage.warning('请上传小于20M的图片')
        return
      }


      if (e.target.files[0]) {
        proxy
          .$post(
            "v3/oss/front/upload",
            {
              source: "businessLicense",
              file: e.target.files[0],
            },
            "form"
          )
          .then((res) => {
            if (res.state == 200) {
              if (type == "businessLicense") {
                form.data.businessLicense = res.data.path;
                businessLicenseUrl.value = res.data.url;
              } else if (type == "idFrontImage") {
                form.data.idFrontImage = res.data.path;
                idFrontImageUrl.value = res.data.url;
              } else {
                form.data.idBackImage = res.data.path;
                idBackImageUrl.value = res.data.url;
              }
            }
          });
      }
    };
    //切换地址
    const changeAddress = () => {
        console.log(form.data.addressAll)
    };
    onMounted(() => {
      initData();
      getState();
    })

    return {
      changeAddress,  
      L,
      step,
      cascaderAddr,
      nextStep,
      pic1,
      pic2,
      pic3,
      pic4,
      pic_wait,
      pic_success,
      pic_fail,
      area,
      areaDataArray,
      cascaderProps,
      form,
      refuseReason,
      ruleForm,
      rules,
      getImage,
      getState,
      getInfo,
      businessLicenseUrl,
      idFrontImageUrl,
      idBackImageUrl,
    };
  },



};
</script>

<style lang="scss" scoped>
@import "../../style/authentication.scss";
</style>
<style>
.writeing .el-input__inner {
  width: 390px;
  height: 34px;
  font-size: 12px;
  padding-right: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.writeing .el-textarea {
  width: 390px;
  font-size: 12px;
}

.writeing .el-form-item {
  margin-bottom: 0;
}

.writeing .el-form-item__content {
  flex: unset;
  width: 530px;
}

.writeing .el-form-item__error {
  top: 12px;
  left: unset;
  right: 0;
  transform: translateX(180px);
  width: 170px;
  cursor: default;
}
</style>